
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButtons,
  IonButton,
  IonBackButton,
  IonIcon,
  IonList,
  IonSelectOption,

  onIonViewDidEnter
 } from '@ionic/vue';

import { checkmark } from 'ionicons/icons';

import { defineComponent, ref } from '@vue/runtime-core';
import { useRouter, useRoute } from 'vue-router';
import { isApiError } from '@/lib/api-endpoints';
import MInput from '@/components/MInput.vue';
import MSelect from '@/components/MSelect.vue';
import MTextarea from '@/components/MTextarea.vue';
import { useStore } from '@/store';
import * as yup from 'yup';
import t from '@/translation';
import { useForm } from 'vee-validate';
import { guestStatuses } from '@/lib/guest-statuses'

export default  defineComponent({
  name: 'WeddingInfo',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonButtons,
    IonButton,
    IonBackButton,
    IonIcon,
    IonList,
    //IonListHeader,
    //IonGrid,
    //IonRow,
    //IonCol,
    //IonItem,
    //IonLabel,
    //IonDatetime,
    IonSelectOption,


    MInput,
    MSelect,
    MTextarea,
    //MPassword,
  },
  setup(_props){

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const isEdit = ref(false);

    const guestSchema = yup.object({
       /* eslint-disable @typescript-eslint/camelcase */
      id: yup.number().notRequired(),
      first_name: yup.string().required().max(50).label(t("First Name")),
      last_name: yup.string().default("").max(50).label(t("Last Name")),
      address_line_1: yup.string().default("").max(255).label(t("Address Line 1")),
      address_line_2: yup.string().default("").max(255).label(t("Address Line 2")),
      //city: yup.string().default("").max(255).label(t("City")),
      suburb: yup.string().default("").max(255).label(t("Suburb")),
      state: yup.string().default("").max(255).label(t("State")),
      postcode: yup.string().default("").max(255).label(t("PostCode")),
      country: yup.string().default("").max(255).label(t("Country")),
      email: yup.string().email().default("").max(50).label(t("Email")),
      phone: yup.string().default("").max(50).label(t("Phone")),
      table: yup.string().default("").max(50).label(t("Table")),
      dietary: yup.string().default("").max(255).label(t("Dietary")),
      invited: yup.number().required().min(0).max(3).label("Status"),
      special_request: yup.string().default("").max(65535).label(t("Special Request")),
       /* eslint-enable @typescript-eslint/camelcase */
    });

    // Create a form context with the validation schema
    const { values, validate, resetForm } = useForm({
      validationSchema: guestSchema,
    });

    // Load up the latest user details when entering this page
    onIonViewDidEnter(async () => {
      resetForm();
      isEdit.value = false;
      const id = parseInt(route.params.guestId as string);
      if (id){
        const guest = store.state.guests.find( element => element.id == id );
        // populate the form with the details of the category to be edited.
        if (guest){
          isEdit.value = true;
          /* eslint-disable @typescript-eslint/camelcase */
          values.id = id;
          values.first_name = guest.first_name;
          values.last_name = guest.last_name;
          values.address_line_1 = guest.address_line_1;
          values.address_line_2 = guest.address_line_2;
          //values.city = guest.city;
          values.suburb = guest.suburb;
          values.state = guest.state;
          values.postcode = guest.postcode;
          values.country = guest.country;
          values.email = guest.email;
          values.phone = guest.phone;
          values.table = guest.table;
          values.dietary = guest.dietary;
          values.invited = guest.invited;
          values.special_request = guest.special_request;
          /* eslint-enable @typescript-eslint/camelcase */
        } 
      } else {
        values.invited = 3; // "Invited"
        const statusFromRoute = guestStatuses.get(route.params.guestId as string);
        if (statusFromRoute){
          values.invited = statusFromRoute.statusId;
        }
      }
    });

    async function onSubmitForm(){
      const result = await validate();
      if (result.valid){
        /* eslint-disable-next-line @typescript-eslint/camelcase */
        const guest = await store.dispatch('saveGuest', { ... values, wedding_id: store.getters.wedding.id });
        if (! isApiError(guest)){
          router.go(-1); // go back to the last page.
          window.setTimeout(resetForm, 2000); // delayed so that validation errors are not seen before the page transistions.
        }
      } 
    }

    return {
      onSubmitForm,
      checkmark,
      guestStatuses,
      route,
      isEdit,
    }
  }
});
