import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, withKeys as _withKeys } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_m_input = _resolveComponent("m-input")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_m_select = _resolveComponent("m-select")!
  const _component_m_textarea = _resolveComponent("m-textarea")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, {
                    "default-href": "/tabs/guests",
                    text: _ctx.$t('Cancel')
                  }, null, 8, ["text"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "primary" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _withModifiers(_ctx.onSubmitForm, ["prevent"])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "end",
                        icon: _ctx.checkmark
                      }, null, 8, ["icon"]),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('Save')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.isEdit ? _ctx.$t('Edit Guest') : _ctx.$t('Add Guest')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode("form", {
            action: "#",
            onSubmit: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onSubmitForm()), ["prevent"])),
            onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.onSubmitForm()), ["enter"]))
          }, [
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                _createVNode(_component_m_input, {
                  label: _ctx.$t('First Name'),
                  name: "first_name"
                }, null, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Last Name'),
                  name: "last_name"
                }, null, 8, ["label"]),
                _createVNode(_component_m_select, {
                  label: _ctx.$t('Status'),
                  name: "invited"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.guestStatuses.values(), (status) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: status.statusId,
                        value: status.statusId
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(status.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Address Line 1'),
                  name: "address_line_1"
                }, null, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Address Line 2'),
                  name: "address_line_2"
                }, null, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Suburb'),
                  name: "suburb"
                }, null, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('State'),
                  name: "state"
                }, null, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Postcode'),
                  name: "postcode"
                }, null, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Country'),
                  name: "country"
                }, null, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Email'),
                  name: "email"
                }, null, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Phone'),
                  name: "phone"
                }, null, 8, ["label"]),
                _createVNode(_component_m_select, {
                  label: _ctx.$t('Table'),
                  name: "table"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_Fragment, null, _renderList(100, (tableNum) => {
                      return _createVNode(_component_ion_select_option, {
                        key: tableNum,
                        value: tableNum
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("Table #") + tableNum), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"])
                    }), 64))
                  ]),
                  _: 1
                }, 8, ["label"]),
                _createVNode(_component_m_input, {
                  label: _ctx.$t('Dietary'),
                  name: "dietary"
                }, null, 8, ["label"]),
                _createVNode(_component_m_textarea, {
                  label: _ctx.$t('Special Request(s)'),
                  name: "special_request"
                }, null, 8, ["label"])
              ]),
              _: 1
            })
          ], 32)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}